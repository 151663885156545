
import { Modal, message, notification } from 'antd'
import { Localization } from '@lib/i18n/localization'
import { Publisher } from '@prospective/pms-js-utils'
import { useCallback, useEffect } from 'react'

export const unexpectedErrorModal = logNumber => {
    Modal.error({
        title: 'Ooops...',
        content: (
            <>
                <p>{Localization.locale('unexpectedError', { logNumber })}</p>
                <p>{Localization.locale('refreshRequest')}</p>
            </>
        ),
    })
}

export const infoModal = (message, title = 'Info') => {
    Modal.info({
        title,
        content: (
            <>
                <p>{message}</p>
            </>
        ),
    })
}

export const warningModal = (message, title = 'Info') => {
    Modal.warn({
        title,
        content: (
            <>
                <p>{message}</p>
            </>
        ),
    })
}

/**
 * @param {ModalFuncProps} options Ant Modal function properties
 */
export const errorModal = options => {
    Modal.error(options)
}

export const successModal = options => {
    Modal.success(options)
}

const MESSAGE_DURATION = 7
export const infoMessage = (content, cb) => message.info(content, [MESSAGE_DURATION]).then(cb)
export const successMessage = (content, cb) => message.success(content, [MESSAGE_DURATION]).then(cb)
export const errorMessage = (content, cb) => message.error(content, [MESSAGE_DURATION]).then(cb)

const [errorNotifications, triggerErrorNotification] = Publisher()
export const GlobalNotifications = () => {
    const [api, contextHolder] = notification.useNotification()

    const onErrorNotification = useCallback(notification => {
        const description = notification.errors?.length > 1
            ? <ul>{notification.errors.map((errorMessage, index) =>
                <li key={index}>{errorMessage}</li>)}
              </ul>
            : notification.errors?.length === 1
            ? notification.errors.at(0)
            : notification.description
        api.error({
            showProgress: true,
            pauseOnHover: true,
            // placement: 'top',
            ...notification,
            description
        })
    }, [api])

    useEffect(() => {
        errorNotifications.subscribe(onErrorNotification)
        return () => {
            errorNotifications.unsubscribe(onErrorNotification)
        }
    }, [])

    return (<>{contextHolder}</>)
}

/**
 *
 * @param notification
 * @param [notification.message]
 * @param [notification.description]
 * @param [notification.errors]
 * @param [notification.placement]
 * @param [notification.pauseOnHover]
 * @param [notification.showProgress]
 * @param [notification.duration]
 * @returns {*}
 */
export const errorNotification = triggerErrorNotification
