import { OAuthInterceptor } from './oauth_interceptor'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { UserSession } from '@login/user-session'
import { formatISODate, Publisher, timeout, tryCatch } from '@prospective/pms-js-utils'
import { Employee2 } from '@structures/employee.js'
import { JobAd } from '@structures/job.js'
import { JobBoosterServiceError } from '@services/job_booster_service_error.js'
import { UserPreferences } from '@modules/user_preferences/user_preferences.js'
import { Localization } from '@lib/i18n/localization.js'
import { Job } from '@structures/jobAd.js'
import { CONFIG } from '@configuration/config_variables.js'

export { JobBoosterServiceError } from '@services/job_booster_service_error.js'
Logger('JobBoosterService', CATEGORIES.MAIN)
const getApiBaseUrl = () => {
    const hostname = document.location.hostname
    if (hostname === 'anzeigencenter.in-dir-steckt-zukunft.de')
        return 'https://anzeigencenter.in-dir-steckt-zukunft.de/api'
    const topLevelDomain = topLevelDomains.find(domain => hostname.endsWith(domain))
    const secondLevelDomain = topLevelDomains.reduce((result, tld) => result.replace(`.${tld}`, ''), hostname)

    const environmentSubdomain = secondLevelDomain.startsWith('stage.')
        ? 'stage.'
        : secondLevelDomain.startsWith('dev.')
          ? 'dev.'
          : secondLevelDomain.startsWith('localhost') && CONFIG.environment === 'stage'
            ? 'stage.'
            : secondLevelDomain.startsWith('localhost') && CONFIG.environment === 'development'
              ? 'dev.'
              : ''

    const domainComponents = secondLevelDomain.split('.')
    const domain = secondLevelDomain.includes('localhost') ? 'localhost' : domainComponents[domainComponents.length - 1]
    const apiTopLevelDomain = topLevelDomain ? `.${topLevelDomain}` : ''

    return domain === 'localhost'
        ? `${document.location.protocol}${document.location.host}`
        : `https://${environmentSubdomain}api.${domain}${apiTopLevelDomain}`
}

const topLevelDomains = ['de', 'ch']
const apiBaseUrl = getApiBaseUrl()

export const interceptors = new Set([OAuthInterceptor()])
const applyInterceptors = request =>
    Array.from(interceptors.values()).reduce((request, interceptor) => interceptor.intercept(request), request)

export const fetchData = async (url, body, method, signal, customHeaders) => {
    let options = customHeaders
        ? customHeaders
        : {
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              },
              method: method || 'POST',
          }

    if (body) Reflect.set(options, 'body', JSON.stringify(body))

    const request = applyInterceptors(new Request(url.toString(), options))
    let response

    try {
        response = await fetch(request, { signal })
    } catch (error) {
        if (error.name === 'AbortError')
            throw new JobBoosterServiceError('Request abort', {
                cause: error,
                type: JobBoosterServiceError.ABORT_ERROR,
            })
        throw new JobBoosterServiceError('Unknown service error', {
            cause: error,
            type: JobBoosterServiceError.UNKNOWN_ERROR,
        })
    }

    if (!response.ok) {
        if (response.status === 400) {
            throw new JobBoosterServiceError(`Bad request (${response.status})`, {
                cause: { response, code: response.status, message: await response.json() },
                type: JobBoosterServiceError.API_ERROR,
            })
        } else if (response.status === 401) {
            UserSession.closeSession()
            throw new JobBoosterServiceError(`Invalid session (${response.status})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        } else if (response.status === 403)
            throw new JobBoosterServiceError(`Insufficient permissions (${response.status})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        else if (response.status >= 400 && response.status < 500) {
            throw new JobBoosterServiceError(`Client error, status ${response.status} (${response.statusText})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        }

        if (response.status >= 500 && response.status < 600)
            throw new JobBoosterServiceError(`Server error, status ${response.status} (${response.statusText})`, {
                cause: { response, code: response.status, message: await response?.json() },
                type: JobBoosterServiceError.API_ERROR,
            })
        else
            throw new JobBoosterServiceError(`Unknown error, status ${response.status} (${response.statusText})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
    }

    const contentType = response.headers.get('Content-Type')

    try {
        return response && response.status !== 204
            ? contentType && (contentType.includes('application/octet-stream') || contentType.includes('image/'))
                ? response.blob()
                : response.json()
            : []
    } catch (parseError) {
        throw new JobBoosterServiceError('Parse error', {
            cause: parseError,
            type: JobBoosterServiceError.PARSE_ERROR,
        })
    }
}

export const getData = (url, signal) => fetchData(url, undefined, 'GET', signal)
export const getImage = (url, signal) => fetchData(url, undefined, 'GET', signal, { headers: { accept: 'image/*' } })
export const postData = (url, body, signal) => fetchData(url, body, 'POST', signal)
export const putData = (url, body, signal) => fetchData(url, body, 'PUT', signal)
export const deleteData = (url, signal) => fetchData(url, undefined, 'DELETE', signal)
export const patchData = (url, body, signal) => fetchData(url, body, 'PATCH', signal)

// TODO - Optimize this
export const fetchHtml = async (url, body, method, signal) => {
    let options = {
        method: method || 'GET',
        headers: {
            accept: 'text/html;charset=UTF-8',
            'Content-Type': 'application/json',
        },
    }

    if (body) Reflect.set(options, 'body', JSON.stringify(body))
    const request = applyInterceptors(new Request(url.toString(), options))
    let response

    try {
        response = await fetch(request, { signal })
    } catch (error) {
        if (error.name === 'AbortError')
            throw new JobBoosterServiceError('Request abort', {
                cause: error,
                type: JobBoosterServiceError.ABORT_ERROR,
            })
        throw new JobBoosterServiceError('Unknown service error', {
            cause: error,
            type: JobBoosterServiceError.UNKNOWN_ERROR,
        })
    }

    if (!response.ok) {
        if (response.status === 400) {
            throw new JobBoosterServiceError(`Bad request (${response.status})`, {
                cause: { response, code: response.status, message: await response.json() },
                type: JobBoosterServiceError.API_ERROR,
            })
        } else if (response.status === 401) {
            UserSession.closeSession()
            throw new JobBoosterServiceError(`Invalid session (${response.status})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        } else if (response.status === 403)
            throw new JobBoosterServiceError(`Insufficient permissions (${response.status})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        else if (response.status >= 400 && response.status < 500) {
            throw new JobBoosterServiceError(`Client error, status ${response.status} (${response.statusText})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
        }

        if (response.status >= 500 && response.status < 600)
            throw new JobBoosterServiceError(`Server error, status ${response.status} (${response.statusText})`, {
                cause: {
                    response,
                    code: response.status,
                    message: `Server error, status ${response.status} (${response.statusText})`,
                },
                type: JobBoosterServiceError.API_ERROR,
            })
        else
            throw new JobBoosterServiceError(`Unknown error, status ${response.status} (${response.statusText})`, {
                cause: { response, code: response.status },
                type: JobBoosterServiceError.API_ERROR,
            })
    }

    try {
        return response && response.status !== 204 ? response.text() : []
    } catch (parseError) {
        throw new JobBoosterServiceError('Parse error', {
            cause: parseError,
            type: JobBoosterServiceError.PARSE_ERROR,
        })
    }
}

const structureOrganizationNodes = nodes =>
    nodes.map(node => ({
        ...node,
        label: node.name,
        key: node.id,
        level: node.level,
        hasAccess: node.hasAccess,
        isActive: node.isActive,
        value: node.id,
        children: node.children ? structureOrganizationNodes(node.children) : undefined,
    }))

export const ProAnalyticsService = {
    getProAnalyticsPermissions: async (abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/permissions/`)
        return await getData(url, abortSignal)
    },

    getProAnalyticsDictionaries: async (params = {}, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }
        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }

        const url = new URL(`${apiBaseUrl}/asl/api/dictionary`)
        return await postData(url, body, signal)
    },

    getJobCountStatistics: async (params, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }
        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }
        if (params.searchTerm) body.searchTerm = params.searchTerm
        const url = new URL(`${apiBaseUrl}/asl/api/anzahlstellen`)
        return await postData(url, body, signal)
    },

    getPerformanceStatistics: async (params, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            typ: params.performanceStatisticsActionType,
            resolution: params.resolution,
            filters: { ...defaultParams.filters, ...params.filters },
        }

        if (params.searchTerm) body.searchTerm = params.searchTerm
        if (params.currency) body.currency = params.currency
        const url = new URL(`${apiBaseUrl}/asl/api/perfstats`)
        return await postData(url, body, signal)
    },

    getCostsStatistics: async (params, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }

        if (params.searchTerm) body.searchTerm = params.searchTerm
        if (params.currency) body.currency = params.currency
        const url = new URL(`${apiBaseUrl}/asl/api/performance`)
        return await postData(url, body, signal)
    },

    getSearchParamsBody: params => {
        const defaultSearchParams = {
            size: 5,
        }
        if (params.from !== undefined) defaultSearchParams.von = formatISODate(params.from)
        if (params.to !== undefined) defaultSearchParams.bis = formatISODate(params.to)
        if (params.searchTerm !== undefined) defaultSearchParams.searchTerm = params.searchTerm
        if (params.filters) defaultSearchParams.filters = params.filters
        if (params.size) defaultSearchParams.size = params.size
        return defaultSearchParams
    },

    proAnalyticsSearch: async (params, signal) => {
        const body = getSearchParamsBody(params)
        const url = new URL(`${apiBaseUrl}/asl/api/search`)
        return await postData(url, body, signal)
    },

    getCandidateJourney: async (params = {}, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }
        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }
        if (params.searchTerm) body.searchTerm = params.searchTerm

        const url = new URL(`${apiBaseUrl}/asl/api/candidatejourney`)
        return await postData(url, body, signal)
    },

    getCareerCenterStatistics: async (params = {}, signal) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            typ: 'UTM',
            filters: {},
        }
        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            typ: params.typ,
            filters: { ...defaultParams.filters, ...params.filters },
        }
        if (params.searchTerm) body.searchTerm = params.searchTerm

        const url = new URL(`${apiBaseUrl}/asl/api/careercenter`)

        return await postData(url, body, signal)
    },

    getSingleJobDatesSpan: async (jobId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/performance/${jobId}`)
        return await getData(url, abortSignal)
    },

    getSingleJobCostStatistics: async (id, params = {}, abortSignal = undefined) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }

        if (params.currency) body.currency = params.currency

        const url = new URL(`${apiBaseUrl}/asl/api/performance/${id}`)
        return await postData(url, body, abortSignal)
    },

    getSingleJobCandidateJourney: async (id, params = {}, abortSignal = undefined) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
        }

        const url = new URL(`${apiBaseUrl}/asl/api/candidatejourney/${id}`)
        return await postData(url, body, abortSignal)
    },

    getSingleJobPerformanceStats: async (id, params = {}, abortSignal = undefined) => {
        const now = new Date()
        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {},
            typ: 'VIEW',
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
            typ: params.typ || 'VIEW',
        }

        if (params.currency) body.currency = params.currency

        const url = new URL(`${apiBaseUrl}/asl/api/perfstats/${id}`)
        return await postData(url, body, abortSignal)
    },

    getAllReports: async (abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/reports/list/`)
        return await getData(url, abortSignal)
    },

    getReport: async (reportId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/reports/${reportId}`)
        return await getData(url, abortSignal)
    },

    saveReport: async (report, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/reports/save`)
        return await putData(url, report, abortSignal)
    },

    deleteReport: async (reportId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/asl/api/reports/${reportId}`)
        return await deleteData(url, abortSignal)
    },

    getReportColumns: async () => {
        const url = new URL(`${apiBaseUrl}/asl/api/reports/sources`)
        return await getData(url, undefined)
    },

    generateReport: async (params = {}, abortSignal = undefined, report) => {
        const now = new Date()

        const defaultParams = {
            from: new Date(now.getFullYear(), now.getMonth()),
            to: now,
            filters: {
                recruiter_id: [],
                medium_id: [],
                recruiter_name_keyword: [],
                hk_id: [],
            },
            report: {},
            language: 'de',
        }

        const body = {
            von: formatISODate(params.from || defaultParams.from),
            bis: formatISODate(params.to || defaultParams.to),
            filters: { ...defaultParams.filters, ...params.filters },
            report: { ...defaultParams.report, ...report },
        }

        if (params.searchTerm) body.searchTerm = params.searchTerm
        if (params.currency) body.currency = params.currency
        if (params.language) body.language = params.language

        const url = new URL(`${apiBaseUrl}/asl/api/reports/query`)
        return postData(url, body, abortSignal)
    },

    getCustomerSettings: async nodeIds => {
        if (!nodeIds || nodeIds.length === 0) return
        const url = new URL(`${apiBaseUrl}/asl/api/settings`)
        const body = { nodeIds, explicit: true }
        return await postData(url, body)
    },

    getHierarchyStructure: async () => {
        const url = new URL(`${apiBaseUrl}/asl/api/hierarchie`)
        const response = await getData(url)
        try {
            return response ? structureOrganizationNodes(response) : []
        } catch (error) {
            throw new JobBoosterServiceError('Invalid organization structure', { cause: error })
        }
    },
}

export const PublicService = {
    getDictionaries: async () => {
        return {}
    },

    getRegisterToken: async (token, signal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/public/static/token/${token}`)
        return await getData(url)
    },

    getIndustries: async lang => {
        const url = new URL(`${apiBaseUrl}/jb3/public/static/industries`)
        if (lang) url.searchParams.set('lang', lang)
        return await getData(url)
    },

    getBackendVersionInfo: async (abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/actuator/info`)
        return await getData(url, abortSignal)
    },
}

/**
 * Common Service endpoints. Marketplace-specific endpoint are prefixed with `mp`, JobBooster-specific endpoints with `pms`.
 *
 * Initially, the prefixes were not used. Calling an endpoint without a prefix will use the old endpoint.
 * Please note it may not exist anymore.
 *
 * Prefixes are passed in the plugins, where the plugin-specific services are defined. Plugins use this function
 * to compose specific services with default endpoints.
 * @param {'mp/' | 'pms/' | 'ats/' | ''} [prefix]
 * @returns {Object}
 * @example
 * const PluginSpecificService = {
 *     ...JobBoosterService('mp')
 * }
 */
export const getJobBoosterService = (prefix = '') => ({
    uploadAWSImage: (file, nodeId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}images`)
        const { locale } = Localization
        const [onProgress, publishProgress] = Publisher()

        const promise = new Promise((resolve, reject) => {
            const progressHandler = function (event) {
                publishProgress(event.loaded / event.total)
            }
            const loadHandler = event => {
                if (xhr.status === 404)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: event,
                            type: JobBoosterServiceError.NOT_FOUND,
                        })
                    )
                else if (xhr.status === 403)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: event,
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 415)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaTypeNotSupported') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 413)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaSizeTooBig') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 422)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaProcessing') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status < 200 || xhr.status >= 300) {
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: { code: xhr.status },
                            type: JobBoosterServiceError.UNKNOWN_ERROR,
                        })
                    )
                } else if (xhr.status >= 200 || xhr.status < 300) resolve(JSON.parse(event.target.response))
                else
                    reject(
                        new JobBoosterServiceError('Unhandled upload error', {
                            cause: event,
                            type: JobBoosterServiceError.UNKNOWN_ERROR,
                        })
                    )
            }

            function handleEvent(e) {}

            function addListeners(xhr) {
                xhr.upload.addEventListener('loadstart', handleEvent)
                xhr.upload.addEventListener('load', handleEvent)
                xhr.upload.addEventListener('loadend', handleEvent)
                xhr.upload.addEventListener('progress', progressHandler)
                xhr.upload.addEventListener('error', handleEvent)
                xhr.upload.addEventListener('abort', handleEvent)
                xhr.addEventListener('load', loadHandler)
            }

            const formData = new FormData()
            formData.append('file', file)
            if (nodeId) formData.append('nodeId', nodeId)

            const xhr = new XMLHttpRequest()
            addListeners(xhr)

            xhr.open('POST', url)
            xhr.setRequestHeader('Authorization', `Bearer ${UserSession.token}`)
            xhr.send(formData)
        })

        return {
            onProgress,
            promise,
        }
    },

    uploadFile: (file, type = 'IMAGE', nodeId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}images?type=${type}`)
        const [onProgress, publishProgress] = Publisher()
        const { locale } = Localization

        const promise = new Promise((resolve, reject) => {
            const progressHandler = function (event) {
                // console.debug(`${event.type}: ${event.loaded} bytes transferred`)
                publishProgress(event.loaded / event.total)
            }
            const loadHandler = event => {
                if (xhr.status === 404)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: event,
                            type: JobBoosterServiceError.NOT_FOUND,
                        })
                    )
                else if (xhr.status === 403)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: event,
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 415)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaTypeNotSupported') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 413)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaSizeTooBig') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status === 422)
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: {
                                code: xhr.status,
                                response: { statusText: locale('uploadError.mediaProcessing') },
                            },
                            type: JobBoosterServiceError.FORBIDDEN,
                        })
                    )
                else if (xhr.status < 200 || xhr.status >= 300) {
                    reject(
                        new JobBoosterServiceError('File upload failed', {
                            cause: { code: xhr.status },
                            type: JobBoosterServiceError.UNKNOWN_ERROR,
                        })
                    )
                } else if (xhr.status >= 200 || xhr.status < 300) resolve(JSON.parse(event.target.response))
                else
                    reject(
                        new JobBoosterServiceError('Unhandled upload error', {
                            cause: event,
                            type: JobBoosterServiceError.UNKNOWN_ERROR,
                        })
                    )
            }

            function handleEvent(e) {
                // console.debug(`${e.type}: ${e.loaded} bytes transferred, ${xhr.status}`)
            }

            function addListeners(xhr) {
                xhr.upload.addEventListener('loadstart', handleEvent)
                xhr.upload.addEventListener('load', handleEvent)
                xhr.upload.addEventListener('loadend', handleEvent)
                xhr.upload.addEventListener('progress', progressHandler)
                xhr.upload.addEventListener('error', handleEvent)
                xhr.upload.addEventListener('abort', handleEvent)
                xhr.addEventListener('load', loadHandler)
            }

            const formData = new FormData()
            formData.append('file', file)
            if (nodeId) formData.append('nodeId', nodeId)

            const xhr = new XMLHttpRequest()
            addListeners(xhr)
            xhr.open('POST', url)
            xhr.setRequestHeader('Authorization', `Bearer ${UserSession.token}`)
            xhr.send(formData)
        })
        return {
            onProgress,
            promise,
        }
    },

    deleteLogo: async (image, nodeId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}images/${image.hash}`)
        if (nodeId) url.searchParams.set('nodeId', nodeId)
        return await deleteData(url)
    },

    getFiles: async (type = 'LOGO', nodeId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}images`)
        if (type) url.searchParams.set('type', type)
        if (nodeId) url.searchParams.set('nodeId', nodeId)
        return await getData(url)
    },

    getPermissions: async (companyNode = undefined, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}permissions`)
        if (companyNode) url.searchParams.set('filterNodeId', companyNode)
        return await getData(url, abortSignal)
    },

    getOrderStates: async () => {
        return []
    },

    getLoggedInUser: async () => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}users/me`)
        const result = await getData(url)
        const user = Employee2.fromDTO(result.employee)
        user.userId = result.user_id
        user.companyAdmin = result.multi_companies
        user.jb3_migrated = result.jb3_migrated
        return user
    },

    getUserPreferences: async () => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}users/me/settings`)
        const result = await getData(url)
        return UserPreferences.prepareUserPreferences(result)
    },

    postUserPreferences: async (preferences, signal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}users/me/settings`)
        const preparePreferences = JSON.stringify(preferences.preferences)
        const result = await putData(url, { ...preferences, preferences: preparePreferences }, signal)
        return UserPreferences.prepareUserPreferences(result)
    },

    getJobDetails: async jobId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}jobs/${jobId}`)
        const jobAd = await getData(url)
        return JobAd.fromJobDTO(jobAd)
    },

    getJobPreview: async (jobId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}jobads/${jobId}/preview`)
        return await getData(url, abortSignal)
    },

    postJobOrder: async (jobId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}jobs/${jobId}/orders`)
        const jobAd = await postData(url, null, abortSignal)
        return JobAd.fromJobDTO(jobAd)
    },

    getMediaLists: async (companyId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists`)
        // if (activeNodes !== undefined) url.searchParams.set('nodes', activeNodes.join(','))
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        return await getData(url, abortSignal)
    },

    getMediaList: async id => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists/${id}`)
        return await getData(url)
    },

    deleteMediaList: async (id, signal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists/${id}`)
        return await deleteData(url, signal)
    },

    deleteMediaPreference: async (listId, preferenceId, signal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists/${listId}/preferences/${preferenceId}`)
        return await deleteData(url, signal)
    },

    createMediaList: (payload = {}, signal = undefined) => {
        const body = {
            ...payload,
        }

        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists`)
        return postData(url, body, signal)
    },

    updateMediaList: (payload = {}, signal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}medialists/${payload.id}`)
        return putData(url, payload, signal)
    },

    getAuthConfig: async () => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}users/me/authconfig?requestUrl=${window.location.origin}`)
        const response = await getData(url)

        try {
            return response ? response : []
        } catch (error) {
            throw new JobBoosterServiceError('Cannot fetch auth config', { cause: error })
        }
    },

    getHierarchyNodes: async (permissions = []) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}nodes`)
        if (permissions.length) {
            permissions.forEach((permission, index) => {
                if (index === 0) url.searchParams.set('permissions', permission)
                else url.searchParams.append('permissions', permission)
            })
        }

        const response = await getData(url)
        try {
            return response ? structureOrganizationNodes(response) : []
        } catch (error) {
            throw new JobBoosterServiceError('Invalid organization structure', { cause: error })
        }
    },

    getMediaFilters: async () => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/filters`)
        return await getData(url)
    },

    getCompanyInformation: async (nodeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}nodes/${nodeId}/company`)
        return await getData(url, abortSignal)
    },

    postCompanyInformation: async (companyInformation, nodeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}nodes/${nodeId}/company`)
        return await postData(url, companyInformation, abortSignal)
    },

    getInvoices: async companyId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}invoices`)
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        return await getData(url)
    },

    getInvoicePDF: async invoiceId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}invoices/${invoiceId}`)
        return await getData(url)
    },

    // TODO Remove companyId parameter
    getApplicationChannelSettings: async (hierarchyId, companyId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}company/settings/application`)
        if (hierarchyId) url.searchParams.set('hierarchyNodeId', hierarchyId)
        if (companyId) url.searchParams.set('hierarchyNodeId', companyId)
        return await getData(url)
    },

    getAllMediaPreferences: async () => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/all`)
        return await getData(url)
    },

    postMediaSearch: async (params = {}, signal) => {
        const defaultParams = {
            query: null,
            filter: {
                types: [],
                subjects: [],
                language: [],
                countries: [],
            },
        }

        const body = {
            query: params.query || defaultParams.query,
            filter: { ...defaultParams.filter, ...params.filter },
        }

        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/search`)
        return postData(url, body, signal)
    },

    getOrderMedia: async (page, size, sorter, params, languageCode = 'de', companyNode, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/selection`)

        if (languageCode) url.searchParams.set('locale', languageCode)
        if (companyNode) url.searchParams.set('filterNodeId', companyNode)
        if (params.name) url.searchParams.set('query', params.name)
        if (params.mediaGroup) url.searchParams.set('mediaGroup', params.mediaGroup)
        if (params?.type !== undefined && params.type.length !== 0) url.searchParams.set('type', params.type.join(','))
        if (params?.language !== undefined && params.language.length !== 0)
            url.searchParams.set('language', params.language.join(','))
        if (params?.industry !== undefined && params.industry.length !== 0)
            url.searchParams.set('subject', params.industry.join(','))
        if (params.range) url.searchParams.set('range', true)
        if (params.specials) url.searchParams.set('specials', true)
        if (params.discounted) url.searchParams.set('discounted', true)
        if (params.social) url.searchParams.set('social', true)
        if (sorter) url.searchParams.set('sortBy', sorter)
        if (page !== undefined) url.searchParams.set('page', page)
        if (size !== undefined) url.searchParams.set('size', size)

        return await getData(url, abortSignal)
    },

    updateMedium: async (medium, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/${medium.id}`)
        return await postData(url, medium, abortSignal)
    },

    getOrderMediaTemplates: async (mediumId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/${mediumId}/template`)
        return await getData(url, abortSignal)
    },

    getMedium: async (mediumId, companyNodeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/${mediumId}`)
        if (companyNodeId) url.searchParams.set('filterNodeId', companyNodeId)
        return await getData(url, abortSignal)
    },

    getMediumFilters: async languageCode => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/filters?language=${languageCode}`)
        return await getData(url)
    },

    getMediumClassification: async jobTitle => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}media/classification`)
        url.searchParams.set('title', jobTitle)
        return await getData(url)
    },

    getJobByOrderId: async orderId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}orders/${orderId}/job`)
        const jobAd = await getData(url)
        return Job.fromDTO(jobAd)
    },

    terminateJobPublications: async (ids, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}publications`)
        return await putData(url, { ids }, abortSignal)
    },

    createNewJobFromTemplate: async (orderId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}orders/${orderId}/clone`)
        const result = await getData(url, abortSignal)
        return result.id
    },

    getJobStats: async (payload, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}statistics`)
        return await postData(url, payload, abortSignal)
    },

    getEmployees: async (organizationNodeIds, companyId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees`)
        if (organizationNodeIds?.length) url.searchParams.set('nodes', organizationNodeIds.join(','))
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        const employees = await getData(url, abortSignal)
        return employees.map(Employee2.fromDTO)
    },

    getEmployeesWithType: async (type = 'recruiter', organizationNodeIds = undefined, companyId, abortSignal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees`)

        if (type) {
            url.searchParams.set('type', type)
            if (type === 'recruiter') url.searchParams.set('with_creators', false)
        }
        if (organizationNodeIds?.length) url.searchParams.set('nodes', organizationNodeIds.join(','))
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        const recruiters = await getData(url, abortSignal)
        return recruiters.map(Employee2.fromDTO)
    },

    getEmployeeProfiles: async (organizationNodeIds, companyId, types, withCurrentUser, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/list`)
        if (organizationNodeIds?.length) url.searchParams.set('nodes', organizationNodeIds.join(','))
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        if (types) types.forEach(type => url.searchParams.append('type', type))
        if (withCurrentUser)
            url.searchParams.set('withCurrentUser', types.includes(Employee2.TYPE_BILLING_RECEIVER) ? 'false' : 'true')
        const employees = await getData(url, abortSignal)
        return employees.map(Employee2.fromDTO)
    },

    getEmployeeProfile: async (id, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${id}`)
        const employee = await getData(url, abortSignal)
        return Employee2.fromDTO(employee)
    },

    createEmployee: async (employee, abortSignal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees`)
        const updatedEmployee = await postData(url, employee.toDTO(), abortSignal)
        return Employee2.fromDTO(updatedEmployee)
    },

    updateEmployee: async (employee, abortSignal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employee.id}`)
        const updatedEmployee = await putData(url, employee.toDTO(), abortSignal)
        return Employee2.fromDTO(updatedEmployee)
    },

    addEmployeeType: async (employeeId, type, abortSignal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}/types?type=${type}`)
        const [error, result] = await tryCatch(postData)(url, employeeId, abortSignal)
        // We can remove the parse error check once backend return a 204 instead of 200 with no content
        if (error?.type === JobBoosterServiceError.PARSE_ERROR) return
        return result
    },

    removeEmployeeType: async (employeeId, type, abortSignal) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}/types?type=${type}`)
        const [error, result] = await tryCatch(deleteData)(url, abortSignal)
        // We can remove the parse error check once backend return a 204 instead of 200 with no content
        if (error?.type === JobBoosterServiceError.PARSE_ERROR) return
        return result
    },

    deleteEmployeeImage: async employeeId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}/picture`)
        return await deleteData(url)
    },

    deleteEmployee: async employeeId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}`)
        return await deleteData(url)
    },

    deactivateEmployee: async (employeeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}/deactivate`)
        return await putData(url, null, abortSignal)
    },

    getSubscriptions: async companyId => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}abos`)
        if (companyId) url.searchParams.set('filterNodeId', companyId)
        return await getData(url)
    },

    getAtsConfiguration: async (nodeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}nodes/${nodeId}/atsconfig`)
        const [error, result] = await tryCatch(getData)(url, abortSignal)
        if (error?.type === JobBoosterServiceError.PARSE_ERROR) return
        return result
    },

    getNearestNodeId: async (nodes, nodeId) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}nodes/nearest-parent-node`)
        const body = { ids: nodes || [], node_id: nodeId }
        return await postData(url, body)
    },

    resetEmployeePassword: async (employeeId, abortSignal = undefined) => {
        const url = new URL(`${apiBaseUrl}/jb3/${prefix}employees/${employeeId}/reset-password`)
        return await putData(url, abortSignal)
    },

    createCrawlerJob: async jobAdUrl => {
        const url = new URL(`${apiBaseUrl}/jb3/proxy/jlss/v1/link-crawler/basic`)
        const body = { url: jobAdUrl }
        return postData(url, body)
    },

    pollCrawlerJobStatus: async jobToken => {
        const url = new URL(`${apiBaseUrl}/jb3/proxy/jlss/v1/link-crawler/basic/${jobToken}`)
        return getData(url)
    },

    fetchCrawlerResources: async (jobToken, resource) => {
        const url = new URL(`${apiBaseUrl}/jb3/proxy/jlss/v1/link-crawler/basic/${jobToken}/resource/${resource}/proxy`)
        return getImage(url)
    },

    ...ProAnalyticsService,
    ...PublicService,
    apiBaseUrl,
})

const JobBoosterService = {
    ...getJobBoosterService(),
}

export default JobBoosterService
