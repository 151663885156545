import { Publisher, tryCatch } from '@prospective/pms-js-utils'
import { CATEGORIES, Logger } from '@modules/logging/logger'
import { Localization } from '@lib/i18n/localization'
import { langChoices } from '@lib/i18n/lang_choices'
import { CONFIG } from '../configuration/config_variables'
import Keycloak from 'keycloak-js'
import { Stream } from '@lib/stream/stream2.js'
import { RemoteData } from '@lib/remote_data/remote-data.js'
import JobBoosterService from '@services/job_booster_service.js'

export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
export const AUTHENTICATED = 'AUTHENTICATED'
const logger = Logger('UserSession', CATEGORIES.MAIN)

const topLevelDomains = ['de', 'ch']
const getKeycloakUrl = () => {
    const hostname = document.location.hostname
    const topLevelDomain = topLevelDomains.find(domain => hostname.endsWith(domain))
    const secondLevelDomain = topLevelDomains.reduce((result, tld) => result.replace(`.${tld}`, ''), hostname)

    // VDV doesn't follow the pattern, so here is the special handling for production:
    if (hostname === 'anzeigencenter.in-dir-steckt-zukunft.de')
        return 'https://anzeigencenter.in-dir-steckt-zukunft.de/auth/'

    const environmentSubdomain = secondLevelDomain.startsWith('stage.')
        ? 'stage.'
        : secondLevelDomain.startsWith('dev.')
          ? 'dev.'
          : secondLevelDomain.startsWith('localhost') && CONFIG.environment === 'stage'
            ? 'stage.'
            : secondLevelDomain.startsWith('localhost') && CONFIG.environment === 'development'
              ? 'dev.'
              : ''

    // VDV doesn't follow the pattern, so here is the special handling for non-production environment:
    if (hostname.endsWith('vdv.stellencockpit.de')) return `https://${environmentSubdomain}vdv.stellencockpit.de/auth/`

    const domainComponents = secondLevelDomain.split('.')
    const domain =
        secondLevelDomain === 'localhost'
            ? 'prospective.ch'
            : `${domainComponents[domainComponents.length - 1]}.${topLevelDomain}`

    return `https://${environmentSubdomain}id.${domain}/auth`
}

const keycloak = new Keycloak({
    realm: 'customer',
    url: getKeycloakUrl(),
    clientId: 'analytics-frontend',
    'public-client': true,
})

const data = {
    get hierarchyNodeId() {
        return data.user?.njc?.hierarchy.id
    },
}
const [publisher, publishChange] = Publisher()
const [close, publishCloseSession] = Publisher()
const [started, publishSessionStarted] = Publisher()
const [userChange, publishUserChange] = Publisher()
const [companyChange, publishCompanyChange] = Publisher()
let tokenTimeoutId
let statusPromise

// Keycloak's error object has structure { error: string }
const getKeycloakError = error => (error && error.error ? error.error : error)

const onTokenExpired = () => {
    logger.info('Token expired')
    keycloak
        .updateToken(30)
        .then(() => {
            data.refreshToken = keycloak.refreshToken
            data.token = keycloak.token
            data.timeout = keycloak.tokenParsed.exp * 1000
            logger.info(
                `Token refresh for ${keycloak.tokenParsed.email}. Valid until ${new Date(
                    data.timeout
                ).toLocaleTimeString()}`
            )
            if (tokenTimeoutId) clearTimeout(tokenTimeoutId)
            tokenTimeoutId = setTimeout(onTokenExpired, data.timeout - Date.now())
        })
        .catch(error => {
            logger.error.withError(getKeycloakError(error), 'Token refresh error')
            closeSession()
        })
}
const keycloakInitParams = {
    // onLoad: 'check-sso',
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
}

const initialize = async () => {
    const authenticated = await keycloak.init(keycloakInitParams).catch(error => {
        logger.error.withError(getKeycloakError(error), 'Failed to initialize keycloak')
        throw new Error('Failed to initialize keycloak')
    })
    return authenticated ? AUTHENTICATED : NOT_AUTHENTICATED
}

const checkStatus = async () => {
    if (!statusPromise) statusPromise = initialize()
    return await statusPromise
}

const startSession = ({ tokenParsed, token, refreshToken }) => {
    data.token = token
    data.refreshToken = refreshToken
    data.user = tokenParsed
    data.timeout = tokenParsed.exp * 1000
    data.mandate = tokenParsed?.njc?.mandate
    data.companyNodeId = undefined
    data.status = AUTHENTICATED
    data.companyNodeName = tokenParsed?.njc?.mandate?.agency || undefined
    Logger.addInfo('userEmail', tokenParsed.email)
    logger.info(
        `Starting new session for ${tokenParsed.email}. Valid until ${new Date(data.timeout).toLocaleTimeString()}`
    )
    if (tokenTimeoutId) clearTimeout(tokenTimeoutId)
    tokenTimeoutId = setTimeout(onTokenExpired, data.timeout - Date.now())

    if (data?.user.locale) {
        const currentLocale = localStorage.getItem('current_locale')
        const langLocale = langChoices.find(item => item.tag.includes(data.user.locale))

        if (currentLocale) Localization.setLocale(currentLocale)
        else {
            localStorage.setItem('current_locale', langLocale.tag)
            Localization.setLocale(langLocale.tag)
        }
    }

    publishChange(data)
    publishUserChange(tokenParsed)
    publishSessionStarted(tokenParsed)
}

const setCompanyHierarchyNode = node => {
    data.companyNodeId = node?.id
    data.companyHierarchyNode = node?.id
    data.companyNodeName = node?.name
    publishChange(data)
}

const setAuthConfig = authConfig => {
    data.authConfig = authConfig
    publishChange(data)
}

const setCompany = company => {
    data.company = company
    localStorage.setItem('jb.company', JSON.stringify(company))
    publishChange(data)
    publishCompanyChange(company)
}

export const loadAuthenticationConfig = Stream(async function* () {
    yield RemoteData.pending()
    const [error, result] = await tryCatch(JobBoosterService.getAuthConfig)()
    if (error) {
        const { logNumber } = logger.error.withError(error, 'Failed to load authentication config')
        throw RemoteData.error(locale => locale('authenticationConfig.loadingError', { logNumber }))
            .logNumber(logNumber)
            .cause(error)
    }
    const authConfig = { passwordChangeUrl: result.password_change_url }
    return RemoteData.success().setValue(authConfig)
})

const login = Stream(async function* () {
    if (data.user) return RemoteData.setValue(data.user).success()
    logger.info('Authenticating...')
    yield RemoteData.pending()
    const [error, authenticated] = await tryCatch(keycloak.init)(keycloakInitParams)
    if (error) {
        const { logNumber } = logger.error.withError(getKeycloakError(error), 'Failed to initialize keycloak')
        return RemoteData.error('Keycloak login failed').logNumber(logNumber)
    }
    if (authenticated) {
        startSession({
            tokenParsed: keycloak.tokenParsed,
            token: keycloak.token,
            refreshToken: keycloak.refreshToken,
        })
        logger.debug('Authenticated.')
        return RemoteData.setValue(data.user).success()
    } else {
        logger.info('User not authenticated. Redirection to Keycloak should happen now.')
    }
})

const logout = Stream(async function* () {
    UserSession.closeSession()
})

const closeSession = () => {
    keycloak.logout().then(() => {
        logger.info('Sessions closed')
    })
    data.token = undefined
    data.user = undefined
    data.company = undefined
    data.timeout = undefined
    data.status = NOT_AUTHENTICATED
    // localStorage.removeItem('jb.token')
    localStorage.removeItem('jb.user')
    localStorage.removeItem('jb.company')
    // localStorage.removeItem('jb.timeout')
    publishChange(data)
    publishCloseSession()
    publishUserChange()
    publishCompanyChange()

    localStorage.removeItem('current_locale')
}

/**
 *
 * @type {
 * Publisher |
 * {
 * readonly mandate: (function(): *)|*,
 * AUTHENTICATED: string,
 * NOT_AUTHENTICATED: string,
 * readonly isValid: *,
 * readonly token: Keycloak.token|*,
 * checkStatus: (function(): Promise<string|*>),
 * updateStatus: (function(): Promise<string|*>),
 * startSession: startSession,
 * closeSession: function():void,
 * readonly company: *,
 * readonly state: {readonly hierarchyNodeId: *},
 * userChange: {forgetAll: (function(): void), forget: (function(...[*]=): void), subscribe: (function(...[Function])), unsubscribe: (function(...[Function])), readonly promise: Promise<*>},
 * companyChange: {forgetAll: (function(): void), forget: (function(...[*]=): void), subscribe: (function(...[Function])), unsubscribe: (function(...[Function])), readonly promise: Promise<*>},
 * close: {forgetAll: (function(): void), forget: (function(...[*]=): void), subscribe: (function(...[Function])), unsubscribe: (function(...[Function])), readonly promise: Promise<*>},
 * readonly user: *,
 * setCompany: setCompany,
 * readonly hierarchyNodeId: *,
 * readonly status: string|*
 * }}
 */
export const UserSession = {
    ...publisher,
    close,
    started,
    userChange,
    companyChange,
    startSession,
    setCompany,
    setAuthConfig,
    closeSession,
    checkStatus,
    setCompanyHierarchyNode,
    updateStatus: initialize,
    get status() {
        return data.status
    },
    get state() {
        return data
    },
    get user() {
        return data.user
    },
    get userId() {
        return data.user.njc.user.id
    },
    get company() {
        return data.company
    },
    /**
     * ID of the hierarchy node the user belongs to
     * @returns {number}
     */
    get hierarchyNodeId() {
        return data.user?.njc?.hierarchy.id
    },
    // TODO companyHierarchyNode and companyNodeId hold the same value - unify this
    get companyHierarchyNode() {
        return data.companyNodeId
    },
    /**
     * ID of the hierarchy node the user belongs to
     * or selected hierarchy node (for admin users/agencies)
     * undefined if not selected
     * @returns {number}
     */
    get companyNodeId() {
        return data.companyNodeId
    },
    get token() {
        return data.token
    },
    get isValid() {
        return !!data.token
    },
    get mandate() {
        return data.user?.njc?.mandate
    },
    get authConfig() {
        return data.authConfig
    },
    login,
    logout,
    AUTHENTICATED,
    NOT_AUTHENTICATED,
}
