import { useContext } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { LocationContext } from '@prospective/process-router-react'
import { theme, Tooltip, TreeSelect } from 'antd'
import { InfoIcon } from '@assets/icons/icons'
import { OrganizationHierarchy } from '@utils/organization_hierarchy.js'
import { HierarchyContext } from '@modules/hierarchy/hierarchy.js'
import './company_hierarchy_selector.scss'

const disabledRoutes = [
    'settings/customers',
    'settings/image-library/upload',
    '/profiles/manage',
    'order',
    'fallbackRoute',
    'settings/job-board/customize',
    'mediaList/manage',
    'settings/imageLibraryUpload',
]

const CompanyHierarchySelector = () => {
    const { token } = theme.useToken()
    const viewModel = useViewModel(HierarchyContext)
    const location = useContext(LocationContext)
    const isDisabled = disabledRoutes.some(route => location?.key.includes(route))

    return (
        <div className="company-selector" style={{ backgroundColor: token.jbColorBgSecondary }}>
            <div className="company-selector__content pageSection">
                <div className="company-selector__wrap">
                    <div className="company-selector__wrap-item">
                        <TreeSelect
                            showSearch
                            maxTagCount="responsive"
                            style={{ width: '500px' }}
                            multiple={false}
                            loading={viewModel.hierarchy.isLoading}
                            value={viewModel.hierarchy.value}
                            placeholder={viewModel.hierarchy.placeholder}
                            disabled={isDisabled || viewModel.hierarchy.isLoading}
                            treeData={viewModel.hierarchy.dictionary}
                            filterTreeNode={(value, option) =>
                                option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1
                            }
                            onChange={id => {
                                const node = OrganizationHierarchy(viewModel.hierarchy.dictionary).findById(id)
                                viewModel.hierarchy.setValue(node)
                            }}
                        />
                    </div>
                    {isDisabled && (
                        <div className="company-selector__wrap-item">
                            <Tooltip title={viewModel.hierarchy.description} position="bottom">
                                <InfoIcon />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CompanyHierarchySelector
