import { isObjectEmpty } from '@utils/object_utils.js'
import { BusinessObject, LocalizedValue, UnorderedListValue, Value } from '@structures/business_object.js'
import { CONFIG } from '@configuration/config_variables.js'

const typesMatch = (typeEntry1, typeEntry2) =>
    typeEntry1.type.value === typeEntry2.type && typeEntry1.node_id === typeEntry2.node_id
const EmployeeTypesValue = field => (object, value) => ({ ...object, [field]: value })
EmployeeTypesValue.difference = UnorderedListValue.Difference(typesMatch)
EmployeeTypesValue.compare = (...lists) => EmployeeTypesValue.difference(...lists) === undefined

const EmployeeDescriptor = {
    id: Value,
    userId: Value,
    nodeId: Value,
    nodeLevel: Value,
    personId: Value,
    isValid: Value,
    types: EmployeeTypesValue,
    xingUrl: Value,
    linkedinUrl: Value,
    firstName: LocalizedValue,
    lastName: LocalizedValue,
    fullName: Value,
    salutation: LocalizedValue,
    position: LocalizedValue,
    title: LocalizedValue,
    language: Value,
    email: Value,
    image: Value,
    phone: Value,
    profilePicture: Value,
    systemUser: Value,
    systemUserData: {
        atsUserId: Value,
        atsUserSecret: Value,
        nodeId: Value,
        superUserNodeIds: Value,
    },
    companyAddress: {
        addressId: Value,
        country: Value,
        postalCode: Value,
        web: Value,
        company: LocalizedValue,
        department: LocalizedValue,
        place: LocalizedValue,
        street: LocalizedValue,
        street2: LocalizedValue,
        street3: LocalizedValue,
    },
    customAddress: {
        addressId: Value,
        country: Value,
        postalCode: Value,
        web: Value,
        company: LocalizedValue,
        department: LocalizedValue,
        place: LocalizedValue,
        street: LocalizedValue,
        street2: LocalizedValue,
        street3: LocalizedValue,
    },
}

const fromDTO2 = dto => {
    return Employee2({
        value: dto,
        id: dto['employee_id'],
        isValid: dto['is_valid'],
        firstName: dto['first_name'],
        lastName: dto['last_name'],
        fullName: dto['full_name'],
        userId: dto['user_id'],
        salutation: dto['salutation'],
        position: dto['position'],
        nodeId: dto['node_id'],
        nodeName: dto['node_name'],
        nodeLevel: dto['node_level'],
        title: dto['job_title'],
        language: dto['language'],
        email: dto['email'],
        image: dto['image'],
        phone: dto['phone'],
        department: dto['department'],
        postalCode: dto['postal_code'],
        place: dto['place'],
        country: dto['company_address']?.country || dto['custom_address']?.country,
        profilePicture: dto['profile_picture'],
        linkedinUrl: dto['linkedin_url'],
        xingUrl: dto['xing_url'],
        personId: dto['person_id'],
        systemUser: dto['system_user'],
        systemUserData: {
            atsUserId: dto?.system_user_data?.ats_user_id || undefined,
            atsUserSecret: dto?.system_user_data?.ats_user_secret || undefined,
            nodeId: dto?.system_user_data?.node_id || undefined,
            superUserNodeIds: dto?.system_user_data?.super_user_node_ids,
        },
        types: dto['types']?.map(typeDTO => ({
            id: typeDTO.id,
            nodeId: typeDTO.node_id,
            type: typeDTO.type,
            description: typeDTO.desc,
            nodeName: typeDTO.node_name,
        })),
        companyAddress: createEmployeeAddressInfo(dto['company_address']),
        customAddress: createEmployeeAddressInfo(dto['custom_address']),
    })
}

const toDTO2 = employee => {
    if (!employee) return

    const allValuesEmpty = isObjectEmpty(employee.customAddress)
    if (allValuesEmpty) employee.customAddress = null
    else {
        employee.customAddress = {
            ...employee.customAddress,
            country:
                employee?.value?.company_address?.country ||
                employee?.value?.custom_address?.country ||
                employee?.country ||
                CONFIG.countryOfBusiness.code.toUpperCase(),
        }
    }

    return {
        job_title: employee.title,
        salutation: employee.salutation,
        employee_id: employee.id || null,
        person_id: employee.id || null,
        node_id: employee.nodeId,
        first_name: employee.firstName,
        last_name: employee.lastName,
        full_name: employee.fullName,
        email: employee.email,
        phone: employee.phone,
        department: employee.department,
        street: employee.street,
        language: employee.language,
        position: employee.position,
        image: employee.image,
        postal_code: employee.postalCode,
        place: employee.place,
        country: employee.country,
        profile_picture: employee.profile_picture,
        xing_url: employee.xingUrl,
        linkedin_url: employee.linkedinUrl,
        node_name: employee.nodeName,
        system_user: employee.systemUser,
        system_user_data: {
            ats_user_id: employee?.systemUserData?.atsUserId,
            ats_user_secret: employee?.systemUserData?.atsUserSecret,
            node_id: employee?.systemUserData?.nodeId,
            super_user_node_ids: employee?.systemUserData?.superUserNodeIds,
        },
        ...(!isObjectEmpty(employee.customAddress)
            ? {
                  custom_address: {
                      address_id: employee.customAddress?.addressId || null,
                      postal_code: employee.customAddress.postalCode || '',
                      company: employee.customAddress.company,
                      department: employee.customAddress.department,
                      web: employee.customAddress.web,
                      place: employee.customAddress.place,
                      street: employee.customAddress.street,
                      street2: employee.customAddress.street2,
                      street3: employee.customAddress.street3,
                      country: employee.customAddress.country,
                  },
              }
            : null),
        ...(!isObjectEmpty(employee.companyAddress)
            ? {
                  company_address: {
                      address_id: employee.companyAddress?.addressId || null,
                      postal_code: employee.companyAddress.postalCode || '',
                      company: employee.companyAddress.company,
                      department: employee.companyAddress.department,
                      web: employee.companyAddress.web,
                      place: employee.companyAddress.place,
                      street: employee.companyAddress.street,
                      street2: employee.companyAddress.street2,
                      street3: employee.companyAddress.street3,
                      country: employee.companyAddress.country,
                  },
              }
            : {}),
        types: (employee.types || []).map(entry => ({
            desc: entry.description,
            id: entry.id,
            node_id: entry.nodeId,
            node_name: entry.nodeName,
            type: { value: entry.type },
        })),
    }
}

const EmployeeHelpers = employee => ({
    isAdminUser: employee.nodeLevel < 2,
    isBillingReceiver: employee.types?.some(entry => entry.type === Employee2.TYPE_BILLING_RECEIVER),
    isJobOwner: employee.types?.some(entry => entry.type === Employee2.TYPE_JOB_OWNER),
    isApplicationContact: employee.types?.some(entry => entry.type === Employee2.TYPE_RECRUITER),
    setAsBillingReceiver: (nodeIds = []) => {
        const type = Employee2.TYPE_BILLING_RECEIVER
        const newTypes = employee.types?.filter(entry => entry.type !== type) || []
        newTypes.push(...nodeIds.map(nodeId => ({ nodeId, type })))
        employee.types = newTypes
        return Employee2(employee)
    },
    setAsApplicationContact: (nodeIds = []) => {
        const type = Employee2.TYPE_RECRUITER
        const newTypes = employee.types?.filter(entry => entry.type !== type) || []
        newTypes.push(...nodeIds.map(nodeId => ({ nodeId, type })))
        employee.types = newTypes
        return Employee2(employee)
    },
    setAsJobOwner: (nodeIds = []) => {
        const type = Employee2.TYPE_JOB_OWNER
        const newTypes = employee.types?.filter(entry => entry.type !== type) || []
        newTypes.push(...nodeIds.map(nodeId => ({ nodeId, type })))
        employee.types = newTypes
        return Employee2(employee)
    },

    applicationContactNodes: employee.types?.filter(entry => entry.type === Employee2.TYPE_RECRUITER) || [],
    jobOwnerNodes: employee.types?.filter(entry => entry.type === Employee2.TYPE_JOB_OWNER) || [],
    billingReceiverNodes: employee.types?.filter(entry => entry.type === Employee2.TYPE_BILLING_RECEIVER) || [],
    toDTO: () => toDTO2(employee),
})

export const Employee2 = BusinessObject(EmployeeDescriptor).extend(EmployeeHelpers)

Employee2.fromDTO = fromDTO2
Employee2.TYPE_RECRUITER = 'recruiter'
Employee2.TYPE_BILLING_RECEIVER = 'invoice_recipient'
Employee2.TYPE_CUSTOMER_CUNSULTANT = 'customer_consultant'
Employee2.TYPE_JOB_OWNER = 'job_owner'
Employee2.SYSTEM_USER = 'system_user'
Employee2.TYPE_AGENCY = 'mandate'
Employee2.TYPE_SALES = 'sales'
Employee2.TYPE_PA_HR_MANAGER = 'pa_hr_manager'
Employee2.TYPE_PA_RECRUITER = 'pa_recruiter'
Employee2.TYPE_PA_CAREER_CENTER = 'pa_careercenter'
Employee2.TYPE_PA_RECRUITER_NO_ORD_TERM = 'pa_recr_no_ord_term'

const createEmployeeAddressInfo = info => {
    return {
        addressId: info?.address_id,
        country: info?.country,
        postalCode: info?.postal_code,
        department: {
            de: info?.department?.de || '',
            en: info?.department?.en || '',
            fr: info?.department?.fr || '',
            it: info?.department?.it || '',
        },
        web: info?.web,
        company: {
            de: info?.company?.de || '',
            en: info?.company?.en || '',
            fr: info?.company?.fr || '',
            it: info?.company?.it || '',
        },
        place: {
            de: info?.place?.de || '',
            en: info?.place?.en || '',
            fr: info?.place?.fr || '',
            it: info?.place?.it || '',
        },
        street: {
            de: info?.street?.de || '',
            en: info?.street?.en || '',
            fr: info?.street?.fr || '',
            it: info?.street?.it || '',
        },
        street2: {
            de: info?.street2?.de || '',
            en: info?.street2?.en || '',
            fr: info?.street2?.fr || '',
            it: info?.street2?.it || '',
        },
        street3: {
            de: info?.street3?.de || '',
            en: info?.street3?.en || '',
            fr: info?.street3?.fr || '',
            it: info?.street3?.it || '',
        },
    }
}
