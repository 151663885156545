import { distinct, O, Publisher } from '@prospective/pms-js-utils'
import * as Validation from '@prospective/pms-view-context/validation/validation'

const DependenciesState = (...statePublishers) => {
    const [change, publishChange] = Publisher()
    let state = statePublishers.map(statePublisher => statePublisher.state)
    const onDependencyChange = () => {
        state = statePublishers.map(statePublisher => statePublisher.state)
        publishChange(state)
    }
    statePublishers.forEach(statePublisher => statePublisher.subscribe(onDependencyChange))

    return {
        ...change,
        get state() { return state }
    }
}

const isStatePublisher = object =>
    Reflect.has(object, 'subscribe') &&
    Reflect.has(object, 'unsubscribe') &&
    Reflect.has(object, 'state')

const FieldDescriptor = descriptor => ({
    compare: otherDescriptor => {
        const difference = O(descriptor).excluding('children')
            .compare(O(otherDescriptor).excluding('children').valueOf())
            .valueOf()
        const childrenDifference = Metadata(descriptor?.children).compare(otherDescriptor?.children)
        if (O(childrenDifference).size)
            difference.children = childrenDifference
        return difference
    },
    merge: otherDescriptor => {
        const merged = ({
            ...descriptor,
            ...otherDescriptor,
        })
        if (descriptor?.children || otherDescriptor?.children)
            merged.children = Metadata(descriptor?.children).merge(otherDescriptor?.children)
        return merged
    }
})

export const Metadata = (metadata = {}) => ({
    compare: (otherMetadata = {}) => {
        const keys = distinct([...O(metadata).keys(), ...O(otherMetadata).keys()])
        return O(keys.reduce((result, key) => {
            const descriptorDifference = FieldDescriptor(metadata[key]).compare(otherMetadata[key])
            if (O(descriptorDifference).size)
                result[key] = descriptorDifference
            return result
        }, {}))
            .valueOf()
    },
    merge: otherMetadata => {
        const updatedDescriptors = O(otherMetadata)
            .map((value, key) => FieldDescriptor(metadata[key]).merge(value))
            .valueOf()
        return { ...metadata, ...updatedDescriptors }
    },
    getDefaultValues: () => O(metadata).map(descriptor => descriptor.default).valueOf(),
    createEventPublishers: () => {
        const publishers = {}
        const triggers = {}

        O(metadata).forEach((descriptor, field) => {
            const events = descriptor.actions || descriptor.events || []
            // onChange always available
            events.push('onChange')
            publishers[field] = {}
            triggers[field] = {}

            distinct(events).forEach(event => {
                const [publisher, trigger] = Publisher()
                publishers[field][event] = publisher
                triggers[field][event] = trigger
            })
        })

        return [publishers, triggers]
    }
})

/**
 *
 * @type ViewContextType
 */
export const View = () => {
    const [onMetadataChange, publishMetadataChange] = Publisher()
    const [onValuesChange, publishValuesChange] = Publisher()
    const [onErrorsChange, publishErrorsChange] = Publisher()
    const [update, publishUpdate] = Publisher()
    let metadata = {}
    let values = {}
    let valuesWithDefaults = {}
    let errors = {}
    let validationMessages = {}
    let eventTriggers = {}
    let eventPublishers = {}
    let eventListeners = {}

    const subscribeListeners = (publishers, listeners) =>
        O(publishers).forEach((events, field) =>
            O(events).forEach((publisher, eventName) => {
                const fieldEvents = O(listeners).get(field)
                const eventListeners = O(fieldEvents).get(eventName) || []
                publisher.subscribe(...eventListeners)
            }),
        )

    const unsubscribeListeners = (publishers, listeners) =>
        O(publishers).forEach((events, field) =>
            O(events).forEach((publisher, eventName) => {
                const fieldEvents = O(listeners).get(field)
                const eventListeners = O(fieldEvents).get(eventName) || []
                publisher.unsubscribe(...eventListeners)
            })
        )

    const getState = () => ({ metadata, values, errors, eventTriggers, validationMessages })

    const setState = (state = {}) => {
        const didValuesChange = !O(values).isEqual(state?.values)
        const didMetadataChange = O(Metadata(metadata).compare(state.metadata)).size > 0
        const didErrorsChange = !O(errors).isEqual(state?.errors)
        const didValidationMessagesChange = !O(validationMessages).isEqual(state?.validationMessages)
        const didAnythingChange = didValuesChange || didMetadataChange || didErrorsChange || didValidationMessagesChange

        const previousValues = values
        const nextValuesWithDefaults = O(state.metadata)
            .filter(entry => O(entry).hasKey('default'))
            .map(entry => entry.default)
            .merge(state.values)
            .valueOf()

        const invalidFields = O(state.validationMessages).keys()
        const nextValidationMessages = Validation.validate(
            nextValuesWithDefaults,
            O(state.metadata)
                .filter((entry, key) => invalidFields.includes(key))
                .valueOf()
        )

        metadata = state.metadata
        values = state.values
        valuesWithDefaults = nextValuesWithDefaults
        errors = state.errors
        validationMessages = nextValidationMessages
        if (didMetadataChange) {
            unsubscribeListeners(eventPublishers, eventListeners)
            const [publishers, triggers] = Metadata(metadata).createEventPublishers()
            eventPublishers = publishers
            eventTriggers = triggers
            subscribeListeners(eventPublishers, eventListeners)
        }

        if (didMetadataChange) publishMetadataChange(metadata)
        if (didValuesChange) publishValuesChange(values, previousValues)
        if (didErrorsChange) publishErrorsChange(errors)
        if (didAnythingChange) publishUpdate({
            metadata,
            values: valuesWithDefaults,
            errors,
            eventTriggers,
            validationMessages
        })
    }

    const updateState = (partialState = { metadata: {}, values: {}, errors: {}, validationMessages: {} }) => {
        const { metadata, values, errors, validationMessages } = getState()
        const newMetadata = Metadata(metadata).merge(partialState.metadata)
        const newValues = { ...values, ...partialState.values }
        const newErrors = { ...errors, ...partialState.errors }
        const newValidationMessages = { ...validationMessages, ...partialState.validationMessages }
        setState({
            metadata: newMetadata,
            values: newValues,
            errors: newErrors,
            validationMessages: newValidationMessages
        })
    }

    const reset = () => setState({ ...getState(), values: {}, errors: {}, validationMessages: {}})

    const validateValues = (values, fields = undefined) => {
        if (!fields) fields = O(metadata).keys()
        const validationMetadata = O(metadata)
            .filter((entry, key) => fields.includes(key) && !entry.disabled)
            .valueOf()
        return Validation.validate(values, validationMetadata)
    }

    const updateValidationErrors = validationResult => {
        const { metadata, values, errors, validationMessages } = getState()
        const messages = O({ ...validationMessages, ...validationResult })
            .filter((messages) => messages.length)
            .valueOf()
        setState({ metadata, values, errors, validationMessages: messages })
    }

    const validate = (...fields) => {
        const { values } = getState()
        const fieldList = fields.length ? [...fields] : O(metadata).keys()
        const result = validateValues(values, fieldList)
        updateValidationErrors(result)
        return result
    }

    const clearValidation = (...fields) => {
        const { metadata, values, errors, validationMessages } = getState()
        if (!fields) fields = O(metadata).keys()
        const messages = O(validationMessages)
            .filter((entry, key) => ![...fields].includes(key))
            .valueOf()
        setState({ metadata, values, errors, validationMessages: messages })
    }

    const addEventListeners = listeners => {
        O(listeners)
            .forEach((events, field) => {
                if (!eventListeners[field]) eventListeners[field] = {}
                O(events).forEach((listeners, event) => {
                    if (!eventListeners[field][event]) eventListeners[field][event] = []
                    eventListeners[field][event] = [...eventListeners[field][event], ...listeners]
                })
            })
        subscribeListeners(eventPublishers, listeners)
    }

    const removeEventListeners = listeners => {
        O(listeners)
            .forEach((events, field) => {
                if (!eventListeners[field]) return
                O(events).forEach((listeners, event) => {
                    if (!eventListeners[field][event]) return
                    eventListeners[field][event] = eventListeners[field][event].filter(listener => !listeners.includes(listener))
                })
            })
        unsubscribeListeners(eventPublishers, listeners)
    }

    const debug = {
        getState,
        get eventPublishers() { return eventPublishers },
        get eventListeners() { return eventListeners }
    }

    const view = {
        ...update,
        onMetadataChange,
        onValuesChange,
        onErrorsChange,
        get metadata() { return metadata },
        get values() { return valuesWithDefaults },
        get errors() { return errors },
        get eventTriggers() { return eventTriggers },
        get validationMessages() { return validationMessages },
        setState: state => {
            setState(state)
            return view
        },
        updateState: partialState => {
            updateState(partialState)
            return view
        },
        reset: () => {
            reset()
            return view
        },
        setValues: values => {
            setState({ ...getState(), values })
            return view
        },
        clearValidation,
        validate,
        validateValues,
        updateValidationErrors,
        addEventListeners,
        removeEventListeners,
        debug
    }

    return view
}

View.observe = (...dependencies) => {
    let mapping
    let metadata
    let viewContext = View()

    const dependenciesState = DependenciesState(...dependencies)
    const update = dependenciesState => {
        metadata = mapping(...dependenciesState)
        viewContext.updateState({ metadata })
    }

    dependenciesState.subscribe(update)

    return {
        update: mapFunction => {
            mapping = mapFunction
            update(dependenciesState.state)

            return viewContext
        }
    }
}
