/**
 * @type {{[key: string]: Country}}
 */
export const COUNTRY_OF_BUSINESS = {
    GERMANY: {
        name: 'Germany',
        code: 'de',
    },
    SWITZERLAND: {
        name: 'Switzerland',
        code: 'ch',
    },
    AUSTRIA: {
        name: 'Austria',
        code: 'at',
    },
}

/**
 * @type {{[key: string]: PmsCustomer}}
 */
export const PMS_CUSTOMERS = {
    STELLENCOCKPIT: {
        code: 'SC_APP',
        name: 'Stellencockpit',
        countryOfBusiness: COUNTRY_OF_BUSINESS.GERMANY,
    },
    VDV: {
        code: 'VDV',
        name: 'VDV',
        countryOfBusiness: COUNTRY_OF_BUSINESS.GERMANY,
    },
    RECRUITINGHUB: {
        code: 'RH',
        name: 'RecruitingHub',
        countryOfBusiness: COUNTRY_OF_BUSINESS.SWITZERLAND,
    },
    JOBIQ: {
        code: 'JOBIQ',
        name: 'JobIQ',
        countryOfBusiness: COUNTRY_OF_BUSINESS.SWITZERLAND,
    },
    JOBIQ_DE: {
        code: 'JOBIQ_DE',
        name: 'JobIQ',
        countryOfBusiness: COUNTRY_OF_BUSINESS.GERMANY,
    },
    ATS: {
        code: 'ATS',
        name: 'ATS',
        countryOfBusiness: COUNTRY_OF_BUSINESS.SWITZERLAND,
    },
    DEFAULT: {
        code: 'JB',
        name: 'JobBooster',
        countryOfBusiness: COUNTRY_OF_BUSINESS.SWITZERLAND,
    },
}

/**
 * @type {{[key: string]: PmsCustomer}}
 */
export const CUSTOMER_HOSTNAMES = {
    'jb.ats.prospective.ch': PMS_CUSTOMERS.ATS,
    'stage.jb.ats.prospective.ch': PMS_CUSTOMERS.ATS,
    'dev.jb.ats.prospective.ch': PMS_CUSTOMERS.ATS,
    'localhost.jb.ats.prospective.ch': PMS_CUSTOMERS.ATS,
    'jb.stellencockpit.de': PMS_CUSTOMERS.STELLENCOCKPIT,
    'stage.jb.stellencockpit.de': PMS_CUSTOMERS.STELLENCOCKPIT,
    'dev.jb.stellencockpit.de': PMS_CUSTOMERS.STELLENCOCKPIT,
    'localhost.jb.stellencockpit.de': PMS_CUSTOMERS.STELLENCOCKPIT,
    'stage.vdv.stellencockpit.de': PMS_CUSTOMERS.VDV,
    'vdv.stellencockpit.de': PMS_CUSTOMERS.VDV,
    'anzeigencenter.in-dir-steckt-zukunft.de': PMS_CUSTOMERS.VDV,
    'localhost.vdv.stellencockpit.de': PMS_CUSTOMERS.VDV,
    'jb.recruitinghub.ch': PMS_CUSTOMERS.RECRUITINGHUB,
    'stage.jb.recruitinghub.ch': PMS_CUSTOMERS.RECRUITINGHUB,
    'dev.jb.recruitinghub.ch': PMS_CUSTOMERS.RECRUITINGHUB,
    'localhost.jb.recruitinghub.ch': PMS_CUSTOMERS.RECRUITINGHUB,
    'jb.jobiq.ch': PMS_CUSTOMERS.JOBIQ,
    'stage.jb.jobiq.ch': PMS_CUSTOMERS.JOBIQ,
    'dev.jb.jobiq.ch': PMS_CUSTOMERS.JOBIQ,
    'localhost.jb.jobiq.ch': PMS_CUSTOMERS.JOBIQ,
    'jobbooster.prospective.ch': PMS_CUSTOMERS.DEFAULT,
    'stage.jobbooster.prospective.ch': PMS_CUSTOMERS.DEFAULT,
    'dev.jobbooster.prospective.ch': PMS_CUSTOMERS.DEFAULT,
    'localhost.jobbooster.prospective.ch': PMS_CUSTOMERS.DEFAULT,
    'localhost:9091': PMS_CUSTOMERS.RECRUITINGHUB,
}

const customer = CUSTOMER_HOSTNAMES[window.location.hostname] || PMS_CUSTOMERS.DEFAULT

/**
 * @type {IConfig}
 */
export const CONFIG = {
    environment: import.meta.env.VITE_ENVIRONMENT,
    useServiceMock: import.meta.env.VITE_MOCK === 'true',
    isLocalEnvironment: import.meta.env.VITE_IS_LOCAL_ENVIRONMENT === 'true',
    keycloakauthenticationurl: import.meta.env.VITE_KEYCLOAK_AUTHENTICATION_URL,
    GM_API_KEY: import.meta.env.VITE_GM_API_KEY,
    IMGIX_HOST: import.meta.env.VITE_IMGIX_HOST,
    PRODUCT_FRUITS_KEY: import.meta.env.VITE_PRODUCT_FRUITS_KEY,
    DEFAULT_LOGO_HASH: import.meta.env.VITE_DEFAULT_LOGO_HASH,
    logzIOEndpoint: import.meta.env.VITE_LOGZIO_ENDPOINT,
    GEOCODING_KEY: import.meta.env.VITE_GEOCODING_KEY,
    GTM_KEY: import.meta.env.VITE_GTM_KEY,
    OHWS_TEMPLATES_URL: import.meta.env.VITE_OHWS_TEMPLATES_URL,
    version: import.meta.env.VITE_APPLICATON_VERSION,
    customer,
    countryOfBusiness: customer.countryOfBusiness,
    logoUrl: !window.location.host.startsWith('localhost')
        ? 'shared-resources/logo/large.svg'
        : 'https://stage.jobbooster.prospective.ch/shared-resources/logo/large.svg',

    CAREER_PAGE: window.location.host.includes('recruitinghub')
        ? import.meta.env.VITE_CAREER_PAGE_RH
        : window.location.host.includes('stellencockpit')
          ? import.meta.env.VITE_CAREER_PAGE_SC
          : import.meta.env.VITE_CAREER_PAGE_JOBIQ,
    CAREER_IFRAME: window.location.host.includes('recruitinghub')
        ? import.meta.env.VITE_CAREER_IFRAME_RH
        : window.location.host.includes('stellencockpit')
          ? import.meta.env.VITE_CAREER_IFRAME_SC
          : import.meta.env.VITE_CAREER_IFRAME_JOBIQ,
}
