import { useContext, useEffect, useState } from 'react'
import { LocationContext } from '@prospective/process-router-react'
import { CATEGORIES, Logger } from '@modules/logging/logger.js'
import { useViewModel } from '@lib/view_context/view-model.js'
import { ExitConfirmationContext } from '@views/misc/exit_confirmation/exit_confirmation.context.js'
import ExitConfirmation from '@views/misc/exit_confirmation/exit_confirmation.jsx'
import '../../index.scss'
import DebugTools from '@components/modules/debug/debug_tools/debug_tools.jsx'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils.jsx'
import { ApplicationProcess } from './application.process.js'
import CompanyHierarchySelector from '@components/modules/company_selector/company_hierarchy_selector.jsx'
import ApplicationHeader from '@components/modules/app_header/app_header.jsx'
import ApplicationSubHeader from '@components/modules/app_sub_header/app_sub_header.jsx'
import NotFound from '@views/misc/not_found/not_found.jsx'
import ProAnalyticsDashboard from '@views/pro_analytics/pro_analytics.view.jsx'
import ProAnalyticsJobView from '@views/pro_analytics/pro-analytics-job/pro-analytics-job.view.jsx'
import ProAnalyticsReportView from '@views/pro_analytics/pro_analytics_report/pro_analytics_report.view.jsx'
import Settings from '@views/settings/settings.jsx'
import Help from '@views/help/help.jsx'
import { HierarchyContext } from '@modules/hierarchy/hierarchy.js'
import { ApplicationContext } from '@views/application/application.context.js'
import TasksStatus from '@components/elements/tasks_status/tasks_status.jsx'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, theme, Typography } from 'antd'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { UserOnboarding } from '@components/modules/user_onboarding/user_onboarding.jsx'
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { router } from '@configuration/router.js'
import { routes } from '@configuration/routes.js'
import { QueryParams } from '@prospective/process-router'
import { ModuleController } from '@modules/module_controller.js'
import { GlobalNotifications } from '@components/modules/global_notifications/global_notifications.jsx'

const logger = Logger('App', CATEGORIES.MAIN)
logger.info('Starting Application')

const keycloakAuthentication = () =>
    QueryParams(window.location.hash).has('state') || QueryParams(window.location.hash).has('session_state')
        ? ModuleController.login()
        : Promise.resolve()

function App() {
    const location = useContext(LocationContext)
    const { locale } = useContext(LocaleContext)
    const applicationViewModel = useViewModel(ApplicationContext)
    const exitConfirmationViewModel = useViewModel(ExitConfirmationContext)
    const hierarchyViewModel = useViewModel(HierarchyContext)
    const pluginFeatures = usePluginFeatures()
    const [isKeycloakReady, setIsKeycloakReady] = useState(false)
    const { token } = theme.useToken()

    useEffect(() => {
        PluginManager.onRoutesChange.subscribe(async pluginRoutes => {
            // setIsKeycloakReady(false)
            await keycloakAuthentication()
            setIsKeycloakReady(true)
            router.routes = { ...routes, ...pluginRoutes }
        })

        /*
        Keycloak adds query parameters to the url and removes them after successful authentication.
        That's why we wait with setting the routes until Keycloak is done. Otherwise, in some scenarios the router restores
        the url which contains Keycloak params.
         */
        keycloakAuthentication().then(() => {
            router.routes = routes
        })

        ApplicationProcess()
    }, [])

    useEffect(() => {
        document.body.style.backgroundColor = token.colorBgLayout
    }, [token])

    if (location?.key === undefined) return null

    const renderContent = () => {
        return location?.key?.startsWith('signup') ? (
            pluginFeatures?.signup?.view?.inject()
        ) : (
            <section className="main">
                <header className="app-header">
                    {!location.key.startsWith('proAnalytics') &&
                        hierarchyViewModel.hierarchy.visible &&
                        !location?.isFallbackRoute && <CompanyHierarchySelector />}
                    <ApplicationHeader />
                    <ApplicationSubHeader />
                </header>
                {location?.isFallbackRoute && isKeycloakReady ? (
                    <NotFound />
                ) : hierarchyViewModel.userInteraction.isLoading && !location?.key.startsWith('proAnalytics') ? (
                    <div className="hierarchy-selector-content">
                        <h2>{locale('companySelectorContentMessage')}</h2>
                    </div>
                ) : location?.key === 'pending' ? (
                    <div className={`order__progress full-space`}>
                        <div className="centered-content">
                            <Spin
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                                // spinning={viewModel.jobDetails.isLoading || viewModel.continue.isLoading}
                            />
                            <Typography.Text>{locale('pleaseWait')}</Typography.Text>
                        </div>
                    </div>
                ) : (
                    <div className="app-body">
                        {pluginFeatures.app?.inject()}
                        {location?.key.startsWith('proAnalytics') &&
                            location?.key !== 'proAnalytics/single' &&
                            location?.key !== 'proAnalytics/reports' && (
                                <ProAnalyticsDashboard className="pageSection" />
                            )}
                        {location?.key === 'proAnalytics/single' && <ProAnalyticsJobView />}
                        {location?.key === 'proAnalytics/reports' && <ProAnalyticsReportView />}
                        {location?.key.startsWith('settings') && <Settings className="pageSection" />}
                        {location?.key.startsWith('help') && <Help />}
                    </div>
                )}
                <div className="app-footer">{pluginFeatures.app?.footer?.inject()}</div>
            </section>
        )
    }

    return (
        <div className="App" id="jobBooster">
            <GlobalNotifications />
            <UserOnboarding />
            {renderContent()}
            {exitConfirmationViewModel.isActive.value && <ExitConfirmation />}
            <TasksStatus className="centered-content" tasks={applicationViewModel.initializationTasks.dictionary} />
            <DebugTools />
        </div>
    )
}

export default App
